import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import TextContainer from '../../../components/TextContainer'
import FigmaBox from 'mynt-components/components/FigmaBox'
import React, { useEffect, useMemo, useState } from 'react'
import { Control, Path, useForm, UseFormReturn } from 'react-hook-form'
import { getText, TextKeys } from 'tiger/libs/TextRepository'
import * as Yup from 'yup'
import { InvoiceMethod, InvoiceType } from 'api/swagger/definitions/backoffice'
import Spacings from 'figma/tokens/Spacings'
import { Currencies } from 'flamingo/config/ConfigCurrencies'
import { createInvoiceV2 } from 'api'
import { EnumToOptions, prettifyEnumLabel } from 'helpers/CreditOnCardHelpers'
import { roundDecimals } from 'helpers/math'
import { BO_SUBSCRIPTION_FEE_MODAL_WIDTH } from 'mynt-components/WeakHardCodedSizes'
import Button from 'mynt-components/components/Button'
import ModalForked from 'mynt-components/components/ModalForked'
import RateSelector from 'mynt-components/components/RateSelector'
import { Line } from 'mynt-components/components/StyledComponents'
import { When } from 'mynt-components/components/When'
import { AntiloopTextType } from 'tiger/interfaces/Antiloop'
import { formatAmount } from 'tiger/libs/Common'
import {
  AutoCompleteController,
  DatePickerControllerV2,
  NumberFormatController,
  SelectController,
  TextFieldController
} from '../../../components/react-hook-components'
import dayjs from 'dayjs'
import { YYYY_MM_DD } from 'tiger/Constants'
import { useGetAccountStatus } from '../../../api/cards/queries.ts'
import { Accounts, AccountStatus, isPersonalAccount, Person } from '../../../api/cards/types.ts'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useCustomer } from 'api/react-query/index.ts'

const VAT_FRACTIONS = {
  6: 0.06,
  12: 0.12,
  25: 0.25
}

const BO_DEFAULT_SUBSCRIPTION_FEE = 499
const BO_DEFAULT_SUBSCRIPTION_FEE_VAT_VALUE = 125
const BO_DEFAULT_SUBSCRIPTION_FEE_VAT_FRACTION = VAT_FRACTIONS[25]

type CreateInvoiceDtos = Parameters<typeof createInvoiceV2>[0]

type Props = {
  isOpen: boolean
  customerId: string
  companyName: string
  onClose: () => void
  onSubmit: (values: CreateInvoiceDtos) => void | Promise<any>
  accounts: Accounts[]
  persons: Person[]
}

const CurrencyToLabel = {
  [Currencies.SEK]: 'kr',
  [Currencies.EUR]: '€',
  [Currencies.DKK]: 'dkk'
}

const VatValues = [...Object.values(VAT_FRACTIONS), 'CUSTOM']

const VatToLabel = {
  [VAT_FRACTIONS[6]]: TextKeys.invoicesCreateInvoiceVAT6,
  [VAT_FRACTIONS[12]]: TextKeys.invoicesCreateInvoiceVAT12,
  [VAT_FRACTIONS[25]]: TextKeys.invoicesCreateInvoiceVAT25Selected,
  CUSTOM: TextKeys.invoicesCreateInvoiceVATCustom
}

const toggles: { invoiceTypes: Partial<InvoiceType>[] } = {
  invoiceTypes: [
    InvoiceType.SUBSCRIPTION_FEE,
    InvoiceType.WITHDRAWAL_TO_CUSTOMER,
    InvoiceType.WITHDRAWAL_TO_MYNT,
    InvoiceType.ADMINISTRATION_FEE,
    InvoiceType.REPAYMENT_INVOICE,
    InvoiceType.CASHBACK
  ]
}

const invoiceTypesOptions = EnumToOptions(InvoiceType, prettifyEnumLabel).map((option) => ({
  ...option,
  disabled: !toggles.invoiceTypes.includes(option.value)
}))

const currencyOptions = EnumToOptions({
  [Currencies.SEK]: Currencies.SEK,
  [Currencies.EUR]: Currencies.EUR,
  [Currencies.DKK]: Currencies.DKK,
  [Currencies.NOK]: Currencies.NOK
})

const initialValues = (corporateAccountId: string | undefined, currency: Currencies) => ({
  [InvoiceType.SUBSCRIPTION_FEE]: {
    payload: {
      type: InvoiceType.SUBSCRIPTION_FEE,
      subscriptionFee: {
        value: BO_DEFAULT_SUBSCRIPTION_FEE,
        currency
      },
      vat: {
        value: BO_DEFAULT_SUBSCRIPTION_FEE_VAT_VALUE,
        currency
      },
      sourceAccountId: corporateAccountId
    },
    currency,
    vat: BO_DEFAULT_SUBSCRIPTION_FEE_VAT_FRACTION,
    customVat: 0
  },
  [InvoiceType.WITHDRAWAL_TO_CUSTOMER]: {
    payload: {
      type: InvoiceType.WITHDRAWAL_TO_CUSTOMER,
      amortization: {
        value: 0,
        currency
      },
      sourceAccountId: corporateAccountId
    }
  },
  [InvoiceType.CASHBACK]: {
    payload: {
      type: InvoiceType.CASHBACK,
      amount: {
        value: 0,
        currency
      },
      destinationAccountId: corporateAccountId
    }
  },
  [InvoiceType.WITHDRAWAL_TO_MYNT]: {
    payload: {
      type: InvoiceType.WITHDRAWAL_TO_MYNT,
      amortization: {
        value: 0,
        currency
      },
      sourceAccountId: corporateAccountId
    },
    currency,
    vat: BO_DEFAULT_SUBSCRIPTION_FEE_VAT_FRACTION,
    customVat: 0
  },
  [InvoiceType.ADMINISTRATION_FEE]: {
    payload: {
      type: InvoiceType.ADMINISTRATION_FEE,
      administrationFee: {
        currency,
        value: 0
      },
      vat: {
        value: BO_DEFAULT_SUBSCRIPTION_FEE_VAT_VALUE,
        currency
      },
      sourceAccountId: corporateAccountId
    },
    currency,
    vat: BO_DEFAULT_SUBSCRIPTION_FEE_VAT_FRACTION,
    customVat: 0
  },
  [InvoiceType.REPAYMENT_INVOICE]: {
    payload: {
      accountId: corporateAccountId,
      method: InvoiceMethod.NONE,
      type: InvoiceType.REPAYMENT_INVOICE,
      invoiceDate: dayjs().format(YYYY_MM_DD),
      dueDate: dayjs().format(YYYY_MM_DD),
      amortization: {
        value: 0,
        currency
      }
    }
  }
})

const NaNFallback = (value: number, fallback: number) => (isNaN(value) ? fallback : value)

const createValidationSchema = (type: InvoiceType) => {
  const invoiceTypeValidationSchema = {
    [InvoiceType.SUBSCRIPTION_FEE]: Yup.object().shape({
      subscriptionFee: Yup.object().shape({
        value: Yup.number()
          .min(0, getText(TextKeys.subscriptionFeeBelowZeroErrorLabel, 'en'))
          .typeError(getText(TextKeys.subscriptionFeeTypeErrorLabel, 'en'))
      }),
      customVat: Yup.number()
        .min(0, getText(TextKeys.subscriptionVatBelowZeroErrorLabel, 'en'))
        .typeError(getText(TextKeys.subscriptionVatTypeErrorLabel, 'en'))
    }),
    [InvoiceType.ADMINISTRATION_FEE]: Yup.object().shape({
      administrationFee: Yup.object().shape({
        value: Yup.number()
          .min(0, getText(TextKeys.subscriptionFeeBelowZeroErrorLabel, 'en'))
          .typeError(getText(TextKeys.subscriptionFeeTypeErrorLabel, 'en'))
      })
    }),
    [InvoiceType.WITHDRAWAL_TO_CUSTOMER]: Yup.object().shape({
      amortization: Yup.object().shape({
        value: Yup.number()
          .min(0, getText(TextKeys.subscriptionFeeBelowZeroErrorLabel, 'en'))
          .typeError(getText(TextKeys.subscriptionFeeTypeErrorLabel, 'en'))
      })
    }),
    [InvoiceType.CASHBACK]: Yup.object().shape({
      amount: Yup.object().shape({
        value: Yup.number().min(0.01, 'Must a positive amount').typeError('Invalid fee')
      })
    }),
    [InvoiceType.WITHDRAWAL_TO_MYNT]: Yup.object().shape({
      amortization: Yup.object().shape({
        value: Yup.number()
          .min(0, getText(TextKeys.subscriptionFeeBelowZeroErrorLabel, 'en'))
          .typeError(getText(TextKeys.subscriptionFeeTypeErrorLabel, 'en'))
      })
    }),
    [InvoiceType.REPAYMENT_INVOICE]: Yup.object().shape({
      amortization: Yup.object().shape({
        value: Yup.number().min(0, 'Amount must be above 0').typeError('Amount must be a number'),
        currency: Yup.string().required('Currency is required')
      }),
      accountId: Yup.string().required('Account is required').typeError('Account is required'),
      invoiceDate: Yup.string().required('Invoice date is required').typeError('Invoice date is required'),
      dueDate: Yup.string().required('Due date is required').typeError('Due date is required')
    })
  }

  return Yup.object().shape({
    payload: invoiceTypeValidationSchema[type]
  })
}

type InitialValues = ReturnType<typeof initialValues>

export const InvoicePayment = ({ isOpen, customerId, companyName, accounts, persons, onClose: onModalCancel, onSubmit }: Props) => {
  const { data: customer } = useCustomer(customerId)
  const corporateAccount = accounts.find((account) => account.accountType === 'CORPORATE_ACCOUNT')

  const currency = (customer?.currency ?? Currencies.SEK) as Currencies

  const accountsWithPersons = accounts.map((account) => {
    const person = persons.find((person) => isPersonalAccount(account) && person.id === account.personId)
    return {
      ...account,
      personNameOnAccount: person ? person.fullName : 'Corporate account'
    }
  })

  const accountsWithPersonsOptions: Options = accountsWithPersons.map(
    (account) =>
      ({
        label: account.personNameOnAccount,
        value: account.id
      } satisfies Options[0])
  )

  const [isLoading, setIsLoading] = useState(false)
  const [validationSchema, setValidationSchema] = useState(createValidationSchema(InvoiceType.SUBSCRIPTION_FEE))
  const defaultValues = useMemo(() => initialValues(corporateAccount?.id, currency).SUBSCRIPTION_FEE, [corporateAccount?.id, currency])

  const form = useForm<InitialValues[keyof InitialValues]>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues
  })

  const selectedAccountForRepaymentInvoice: string | undefined = form.watch('payload.accountId')

  const { data: balance } = useGetAccountStatus(customerId, selectedAccountForRepaymentInvoice)

  const type = form.watch('payload.type')

  const onFormSubmit = async (data: any) => {
    setIsLoading(true)

    const payload: CreateInvoiceDtos = {
      ...data.payload,
      customerId,
      sourceAccountId: data.payload.sourceAccountId
    }

    const promiseOrVoid = onSubmit(payload)

    if (promiseOrVoid instanceof Promise) {
      return promiseOrVoid.finally(() => setIsLoading(false))
    }

    setIsLoading(false)
  }

  const handleInvoiceTypeChange = (event) => {
    const type = event.target.value

    const formValues = initialValues(corporateAccount?.id, currency)[type]

    setValidationSchema(createValidationSchema(type))
    form.reset(formValues)
  }

  if (!isOpen) return null

  const isWithdrawal = [InvoiceType.WITHDRAWAL_TO_CUSTOMER, InvoiceType.WITHDRAWAL_TO_MYNT].some((invoiceType) => invoiceType === type)

  return (
    <ModalForked
      fullWidth
      alignHeadingWithCloseButton
      wrapperStyle={{ width: BO_SUBSCRIPTION_FEE_MODAL_WIDTH }} // Form adjusts size when you select different options in the selects without fixed width. Value from figma
      onClose={onModalCancel}
      heading={() => (
        <FigmaBox spacing={Spacings.large} left>
          <TextContainer textKey={TextKeys.invoicesCreateInvoiceHeading} />
        </FigmaBox>
      )}
    >
      <MuiSelectFontFix />
      <FigmaBox spacing={Spacings.large} left top={Spacings.tiny}>
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceSubHeadingCompanyName} text={companyName} />
      </FigmaBox>
      <form onSubmit={form.handleSubmit(onFormSubmit)}>
        <FigmaBox fullWidth spacing={Spacings.large} fullPadding top={Spacings.small} bottom={Spacings.medium} gap={Spacings.small}>
          <Row
            control={form.control}
            labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameInvoiceType}
            name="payload.type"
            type="select"
            options={invoiceTypesOptions}
            onChange={handleInvoiceTypeChange}
          />
          <When is={type !== InvoiceType.CASHBACK && type !== InvoiceType.REPAYMENT_INVOICE}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography
                variant={'subtitle1'}
                sx={{
                  flex: 2
                }}
              >
                Account
              </Typography>
              <Box sx={{ flex: 3 }}>
                <AutoCompleteController
                  name={'payload.sourceAccountId'}
                  label={'Account'}
                  control={form.control}
                  options={accountsWithPersonsOptions}
                />
              </Box>
            </Box>
          </When>
          <When is={type === InvoiceType.SUBSCRIPTION_FEE}>
            <SubscriptionFeeForm form={form as unknown as UseFormReturn<InitialValues['SUBSCRIPTION_FEE'], any>} />
          </When>
          <When is={isWithdrawal}>
            <WithdrawalsForm form={form as unknown as UseFormReturn<WithdrawalsType, any>} />
          </When>
          <When is={type === InvoiceType.CASHBACK}>
            <CashbackForm accounts={accountsWithPersonsOptions} form={form as unknown as UseFormReturn<InitialValues['CASHBACK'], any>} />
          </When>
          <When is={type === InvoiceType.ADMINISTRATION_FEE}>
            <AdministrationFeeForm form={form as unknown as UseFormReturn<InitialValues['ADMINISTRATION_FEE'], any>} />
          </When>
          <When is={type === InvoiceType.REPAYMENT_INVOICE}>
            <RepaymentInvoiceForm
              accounts={accountsWithPersonsOptions}
              balance={balance?.balance}
              form={form as unknown as UseFormReturn<InitialValues['REPAYMENT_INVOICE'], any>}
            />
          </When>
          <FigmaBox top={Spacings.tiny} fullWidth>
            <Button
              disabled={!form.formState.isValid}
              loading={isLoading}
              type="submit"
              textKey={TextKeys.invoicesCreateInvoiceButtonCreateInvoice}
            />
          </FigmaBox>
        </FigmaBox>
      </form>
    </ModalForked>
  )
}

type Options = { value: string; label: string; disabled?: boolean }[]

type RowProps<T extends Record<string, any>> = {
  control: Control<T>
  labelTextKey: AntiloopTextType
  labelText?: string
  name: Path<T>
  type?: 'text' | 'select' | 'number'
  options?: Options
  adornmentTextKey?: AntiloopTextType
  adornmentText?: string
  onChange?: (event: any) => void
}

const Row = <T extends Record<string, any>>({
  control,
  labelTextKey,
  labelText,
  name,
  type = 'number',
  options,
  adornmentTextKey,
  adornmentText,
  onChange
}: RowProps<T>) => (
  <FigmaBox fullWidth justify="space-between" direction="row">
    <FigmaBox fullWidth flex={2} style={{ whiteSpace: 'pre' }} justify="center">
      <TextContainer textKey={labelTextKey} text={labelText} />
    </FigmaBox>
    <StyledTextFieldControllerContainer fullWidth flex={3}>
      <When is={type === 'text'}>
        <TextFieldController
          type={type}
          noLabel
          name={name}
          control={control}
          adornment={adornmentTextKey && <TextContainer text={adornmentText} textKey={adornmentTextKey} />}
        />
      </When>
      <When is={type === 'number'}>
        <NumberFormatController
          control={control}
          name={name}
          labelTextKey={TextKeys.invoiceSpecItemDetailsAmortization}
          adornment={adornmentTextKey && <TextContainer text={adornmentText} textKey={adornmentTextKey} />}
        ></NumberFormatController>
      </When>

      <When is={type === 'select'}>
        <SelectController
          noLabel
          control={control}
          name={name}
          options={options as Options}
          adormentTextKey={adornmentTextKey}
          adormentText={adornmentText}
          onChange={onChange}
        />
      </When>
    </StyledTextFieldControllerContainer>
  </FigmaBox>
)

type SubscriptionFeeFormProps = {
  form: UseFormReturn<InitialValues['SUBSCRIPTION_FEE'], any>
}

const SubscriptionFeeForm = ({ form }: SubscriptionFeeFormProps) => {
  const [showCustomVat, setShowCustomVat] = useState(false)

  const type = form.watch('payload.type')

  const currency = form.watch('currency')
  const vatFraction = form.watch('vat')
  const customVatValue = NaNFallback(form.watch('customVat'), 0)
  const fee = NaNFallback(form.watch('payload.subscriptionFee.value'), 0)
  const vatValue = NaNFallback(form.watch('payload.vat.value'), 0)

  const handleVatChange = (value: number | string) => {
    if (typeof value === 'number') {
      if (showCustomVat) {
        form.setValue('customVat', 0, { shouldValidate: true })
        setShowCustomVat(false)
      }

      return form.setValue('vat', value)
    }

    if (!showCustomVat) setShowCustomVat(true)
  }

  useEffect(() => {
    form.setValue('payload.vat.currency', currency)
    form.setValue('payload.subscriptionFee.currency', currency)
  }, [currency])

  useEffect(() => {
    const finalVatFracton = showCustomVat ? customVatValue / Math.max(fee, 1) : vatFraction

    form.setValue('vat', finalVatFracton)
  }, [customVatValue, fee, showCustomVat, type])

  useEffect(() => {
    const finalVat = showCustomVat ? customVatValue : Math.round(fee * vatFraction)

    form.setValue('payload.vat.value', finalVat)
  }, [vatFraction, fee, showCustomVat, customVatValue, type])

  return (
    <>
      <Row
        control={form.control}
        labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency}
        name="currency"
        type="select"
        options={currencyOptions}
      />
      <FigmaBox top={Spacings.small} bottom={Spacings.min} fullWidth>
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceSubHeadingInvoiceSpecs} />
      </FigmaBox>
      <FigmaBox fullWidth>
        <Row
          type="number"
          control={form.control}
          labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameSubFee}
          name="payload.subscriptionFee.value"
          adornmentTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameSubFeeCurrencyXXX}
          adornmentText={CurrencyToLabel[currency]}
        />
      </FigmaBox>
      <FigmaBox>
        <TextContainer text="VAT" textKey={TextKeys.invoicesCreateInvoiceLabelNameVAT} />
      </FigmaBox>
      <FigmaBox fullWidth>
        <RateSelector defaultValue={BO_DEFAULT_SUBSCRIPTION_FEE_VAT_FRACTION} onValueChange={handleVatChange}>
          {VatValues.map((fraction) => (
            <RateSelector.Item value={fraction} key={fraction} labelTextKey={VatToLabel[fraction]} />
          ))}
        </RateSelector>
      </FigmaBox>
      <When is={showCustomVat}>
        <FigmaBox top={Spacings.tiny} fullWidth>
          <Row
            type="number"
            control={form.control}
            labelTextKey={TextKeys.invoicesCreateInvoiceLabelCustomVAT}
            name="customVat"
            adornmentTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameSubFeeCurrencyXXX}
            adornmentText={CurrencyToLabel[currency]}
          />
        </FigmaBox>
      </When>
      <FigmaBox top={Spacings.tiny} fullWidth direction="row" justify="space-between">
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceLabelNameSubTotal} />
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceLabelNameSubTotalValue} text={formatAmount({ currency, value: fee })} />
      </FigmaBox>
      <FigmaBox fullWidth direction="row" justify="space-between">
        <TextContainer text={`VAT ${roundDecimals(vatFraction * 100, 2)}%`} textKey={TextKeys.invoicesCreateInvoiceLabelNameVAT} />
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceLabelNameVATValue} text={formatAmount({ currency, value: vatValue })} />
      </FigmaBox>
      <FigmaBox spacing={Spacings.min} top bottom fullWidth>
        <Line fullWidth />
      </FigmaBox>
      <FigmaBox fullWidth direction="row" justify="space-between">
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceLabelNameTotal} />
        <TextContainer
          textKey={TextKeys.invoicesCreateInvoiceLabelNameTotalValue}
          text={formatAmount({ currency, value: vatValue + fee })}
        />
      </FigmaBox>
    </>
  )
}

type WithdrawalsType = InitialValues['WITHDRAWAL_TO_CUSTOMER'] | InitialValues['WITHDRAWAL_TO_MYNT']

type WithdrawalsFormFormProps = {
  form: UseFormReturn<WithdrawalsType, any>
}

const WithdrawalsForm = ({ form }: WithdrawalsFormFormProps) => {
  const currency = form.watch('payload.amortization.currency')

  return (
    <>
      <Row
        control={form.control}
        labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency}
        name="payload.amortization.currency"
        type="select"
        options={currencyOptions}
      />
      <FigmaBox top={Spacings.small} bottom={Spacings.min} fullWidth>
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceSubHeadingInvoiceSpecs} />
      </FigmaBox>
      <FigmaBox fullWidth>
        <Row
          type="number"
          control={form.control}
          labelTextKey={TextKeys.invoiceSpecItemDetailsAmortization}
          labelText="Amount"
          name="payload.amortization.value"
          adornmentTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameSubFeeCurrencyXXX}
          adornmentText={CurrencyToLabel[currency]}
        />
      </FigmaBox>
    </>
  )
}

type CashbackFormProps = {
  form: UseFormReturn<InitialValues['CASHBACK'], any>
  accounts: Options
}

const CashbackForm = ({ form, accounts }: CashbackFormProps) => {
  const currency = form.watch('payload.amount.currency')

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography
          variant={'subtitle1'}
          sx={{
            flex: 2
          }}
        >
          Account
        </Typography>
        <Box sx={{ flex: 3 }}>
          <AutoCompleteController name={'payload.destinationAccountId'} label={'Account'} control={form.control} options={accounts} />
        </Box>
      </Box>
      <Row
        control={form.control}
        labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency}
        name="payload.amount.currency"
        type="select"
        options={currencyOptions}
      />
      <FigmaBox top={Spacings.small} bottom={Spacings.min} fullWidth>
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceSubHeadingInvoiceSpecs} />
      </FigmaBox>
      <FigmaBox fullWidth>
        <Row
          type="number"
          control={form.control}
          labelTextKey={TextKeys.invoiceSpecItemDetailsAmortization}
          labelText="Amount"
          name="payload.amount.value"
          adornmentTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameSubFeeCurrencyXXX}
          adornmentText={CurrencyToLabel[currency]}
        />
      </FigmaBox>
    </>
  )
}

type RepaymentInvoiceFormProps = {
  form: UseFormReturn<InitialValues['REPAYMENT_INVOICE'], any>
  accounts: Options
  balance: AccountStatus['balance'] | undefined
}

const RepaymentInvoiceForm = ({ form, accounts, balance }: RepaymentInvoiceFormProps) => {
  const currency = form.watch('payload.amortization.currency')

  useEffect(() => {
    const amortizationValue = -Math.min(balance?.value ?? 0, 0)

    form.setValue('payload.amortization.value', amortizationValue)
  }, [balance])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography
          variant={'subtitle1'}
          sx={{
            flex: 2
          }}
        >
          Account
        </Typography>
        <Box sx={{ flex: 3 }}>
          <AutoCompleteController name={'payload.accountId'} label={'Account'} control={form.control} options={accounts} />
        </Box>
      </Box>
      <FigmaBox direction="row" justify="space-between" align="center" fullWidth>
        <FigmaBox flex={2}>
          <TextContainer textKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency} text="Invoice date" />
        </FigmaBox>
        <FigmaBox flex={3}>
          <DatePickerControllerV2 control={form.control} name="payload.invoiceDate" />
        </FigmaBox>
      </FigmaBox>
      <FigmaBox direction="row" justify="space-between" align="center" fullWidth>
        <FigmaBox flex={2}>
          <TextContainer textKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency} text="Due date" />
        </FigmaBox>
        <FigmaBox flex={3}>
          <DatePickerControllerV2 control={form.control} name="payload.dueDate" />
        </FigmaBox>
      </FigmaBox>
      <FigmaBox top={Spacings.small} bottom={Spacings.min} fullWidth>
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceSubHeadingInvoiceSpecs} />
      </FigmaBox>
      <FigmaBox fullWidth>
        <NumberFormatController
          control={form.control}
          name="payload.amortization.value"
          labelTextKey={TextKeys.invoiceSpecItemDetailsAmortization}
          adornment={CurrencyToLabel[currency]}
        />
      </FigmaBox>
    </>
  )
}

type AdministrationFeeFormProps = {
  form: UseFormReturn<InitialValues['ADMINISTRATION_FEE'], any>
}

const AdministrationFeeForm = ({ form }: AdministrationFeeFormProps) => {
  const [showCustomVat, setShowCustomVat] = useState(false)

  const type = form.watch('payload.type')

  const currency = form.watch('currency')
  const vatFraction = form.watch('vat')
  const customVatValue = NaNFallback(form.watch('customVat'), 0)
  const fee = NaNFallback(form.watch('payload.administrationFee.value'), 0)
  const vatValue = NaNFallback(form.watch('payload.vat.value'), 0)

  const handleVatChange = (value: number | string) => {
    if (typeof value === 'number') {
      if (showCustomVat) {
        form.setValue('customVat', 0, { shouldValidate: true })
        setShowCustomVat(false)
      }

      return form.setValue('vat', value)
    }

    if (!showCustomVat) setShowCustomVat(true)
  }

  useEffect(() => {
    form.setValue('payload.vat.currency', currency)
    form.setValue('payload.administrationFee.currency', currency)
  }, [currency])

  useEffect(() => {
    const finalVatFracton = showCustomVat ? customVatValue / Math.max(fee, 1) : vatFraction

    form.setValue('vat', finalVatFracton)
  }, [customVatValue, fee, showCustomVat, type])

  useEffect(() => {
    const finalVat = showCustomVat ? customVatValue : Math.round(fee * vatFraction)

    form.setValue('payload.vat.value', finalVat)
  }, [vatFraction, fee, showCustomVat, customVatValue, type])

  return (
    <>
      <Row
        control={form.control}
        labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency}
        name="currency"
        type="select"
        options={currencyOptions}
      />
      <FigmaBox top={Spacings.small} bottom={Spacings.min} fullWidth>
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceSubHeadingInvoiceSpecs} />
      </FigmaBox>
      <FigmaBox fullWidth>
        <Row
          type="number"
          control={form.control}
          labelTextKey={TextKeys.invoiceSpecItemDetailsAmortization}
          labelText="Administration fee"
          name="payload.administrationFee.value"
          adornmentTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameSubFeeCurrencyXXX}
          adornmentText={CurrencyToLabel[currency]}
        />
      </FigmaBox>
      <FigmaBox>
        <TextContainer text="VAT" textKey={TextKeys.invoicesCreateInvoiceLabelNameVAT} />
      </FigmaBox>
      <FigmaBox fullWidth>
        <RateSelector defaultValue={BO_DEFAULT_SUBSCRIPTION_FEE_VAT_FRACTION} onValueChange={handleVatChange}>
          {VatValues.map((fraction) => (
            <RateSelector.Item value={fraction} key={fraction} labelTextKey={VatToLabel[fraction]} />
          ))}
        </RateSelector>
      </FigmaBox>
      <When is={showCustomVat}>
        <FigmaBox top={Spacings.tiny} fullWidth>
          <Row
            type="number"
            control={form.control}
            labelTextKey={TextKeys.invoicesCreateInvoiceLabelCustomVAT}
            name="customVat"
            adornmentTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameSubFeeCurrencyXXX}
            adornmentText={CurrencyToLabel[currency]}
          />
        </FigmaBox>
      </When>
      <FigmaBox top={Spacings.tiny} fullWidth direction="row" justify="space-between">
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceLabelNameSubTotal} />
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceLabelNameSubTotalValue} text={formatAmount({ currency, value: fee })} />
      </FigmaBox>
      <FigmaBox fullWidth direction="row" justify="space-between">
        <TextContainer text={`VAT ${roundDecimals(vatFraction * 100, 2)}%`} textKey={TextKeys.invoicesCreateInvoiceLabelNameVAT} />
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceLabelNameVATValue} text={formatAmount({ currency, value: vatValue })} />
      </FigmaBox>
      <FigmaBox spacing={Spacings.min} top bottom fullWidth>
        <Line fullWidth />
      </FigmaBox>
      <FigmaBox fullWidth direction="row" justify="space-between">
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceLabelNameTotal} />
        <TextContainer
          textKey={TextKeys.invoicesCreateInvoiceLabelNameTotalValue}
          text={formatAmount({ currency, value: vatValue + fee })}
        />
      </FigmaBox>
    </>
  )
}

// These fixes some issues that should not be applied everywhere that comes from UX. Would be improved once panda components are created
const MuiSelectFontFix = () => <Global styles={{ '.MuiPaper-root li': { fontSize: '14px !important' } }} />

const StyledTextFieldControllerContainer = styled(FigmaBox)`
  * {
    font-family: Inter !important;
    font-size: 14px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
