import { Global } from '@emotion/react'
import dayjs from 'dayjs'
import useLanguage from 'flamingo/hooks/useLanguage'
import { Formik, FormikHelpers } from 'formik'
import FigmaBox from 'mynt-components/components/FigmaBox'
import FigmaButton from 'mynt-components/components/FigmaButton'
import MaterialFieldFormik from 'mynt-components/components/MaterialFieldFormik'
import ModalExperimental from 'mynt-components/components/ModalExperimental'
import React, { useState } from 'react'
import { TextKeys, getText } from 'tiger/libs/TextRepository'
import * as Yup from 'yup'
import Spacings from 'figma/tokens/Spacings'
import { Countries } from 'flamingo/config/ConfigCountries'
import { Currencies } from 'flamingo/config/ConfigCurrencies'
import useConfigByCountry from 'flamingo/hooks/useConfigByCountry'
import { YYYY_MM_DD } from 'mynt-components/Constants'
import MaterialDatePickerFormik from 'mynt-components/components/MaterialDatePickerFormik'
import MaterialSelectFieldFormik from 'mynt-components/components/MaterialSelectFieldFormik'
import ModalExperimentalFooter from 'mynt-components/components/ModalExperimentalFooter'
import ModalExperimentalHeader from 'mynt-components/components/ModalExperimentalHeader'
import TextContainer from '../../../components/TextContainer'

type FormProps = {
  customerId: string
  ocr: string | undefined
  amount: number
  paymentDate: string
  description: string
  currency: Currencies
}

type Props = {
  isOpen: boolean
  customerId: string
  onClose: () => void
  onSubmit: (values: FormProps, initialValues: FormProps, formikHelpers: FormikHelpers<FormProps>) => void | Promise<any>
  customerCountry: Countries
}

export const ExternalPayment: React.FC<Props> = ({ isOpen, customerId, onClose, onSubmit, customerCountry }) => {
  const language = useLanguage()
  const countryConfig = useConfigByCountry(customerCountry)
  const [isLoading, setIsLoading] = useState(false)

  const initialValues: FormProps = {
    customerId: customerId,
    ocr: undefined,
    amount: 0,
    paymentDate: dayjs().format(YYYY_MM_DD),
    description: '',
    currency: countryConfig.currency
  }

  const validationSchema = Yup.object().shape(
    {
      customerId: Yup.string().when('ocr', {
        is: (ocr: string | undefined) => !ocr,
        then: Yup.string()
          .typeError(getText(TextKeys.mFRegisterPaymentCustomerIDOrOCRError, language))
          .required(getText(TextKeys.mFRegisterPaymentCustomerIDOrOCRError, language)),
        otherwise: Yup.string()
      }),
      ocr: Yup.string().when('customerId', {
        is: (customerId: string | undefined) => !customerId,
        then: Yup.string()
          .required()
          .typeError(getText(TextKeys.mFRegisterPaymentCustomerIDOrOCRError, language))
          .required(getText(TextKeys.mFRegisterPaymentCustomerIDOrOCRError, language)),
        otherwise: Yup.string()
      }),
      amount: Yup.number()
        .typeError(getText(TextKeys.mFRegisterPaymentAmountError, language))
        .required(getText(TextKeys.mFRegisterPaymentAmountError, language)),
      paymentDate: Yup.mixed()
        .typeError(getText(TextKeys.mFRegisterPaymentDateError, language))
        .required(getText(TextKeys.mFRegisterPaymentDateError, language)),
      description: Yup.string()
        .typeError(getText(TextKeys.mFRegisterPaymentDecriptionError, language))
        .required(getText(TextKeys.mFRegisterPaymentDecriptionError, language))
    },
    [['customerId', 'ocr']]
  )

  const handleSubmit = async (values: FormProps, formikHelpers: FormikHelpers<FormProps>) => {
    setIsLoading(true)

    const promiseOrVoid = onSubmit(values, initialValues, formikHelpers)

    if (promiseOrVoid instanceof Promise) {
      return promiseOrVoid.finally(() => {
        onClose()
        setIsLoading(false)
      })
    }

    setIsLoading(false)
    onClose()
  }

  return (
    <>
      {isOpen && (
        <ModalExperimental
          onClose={onClose}
          backOfficeStyling
          heading={() => (
            <ModalExperimentalHeader>
              <TextContainer textKey={TextKeys.registerPaymentHeading} />
            </ModalExperimentalHeader>
          )}
        >
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {(formProps) => (
              <form name="modal_register_external_payment" onSubmit={formProps.handleSubmit}>
                <Global
                  styles={`
                    // Fixes the select menu being hidden behind the modal
                    div[role="presentation"] {
                      z-index: 1300 !important;
                    }
                  `}
                />
                <FigmaBox
                  fullWidth
                  direction="column"
                  justify="space-between"
                  align="flex-start"
                  gap={Spacings.large}
                  top={Spacings.large}
                  bottom={Spacings.large}
                  left={Spacings.medium}
                  right={Spacings.medium}
                >
                  <FigmaBox fullWidth direction="row" align="center" justify="space-between">
                    <FigmaBox fullWidth right spacing={Spacings.large}>
                      <TextContainer textKey={TextKeys.registerPaymentCustomerID} />
                    </FigmaBox>
                    <MaterialFieldFormik
                      fullWidth
                      disabled={!!customerId}
                      name="customerId"
                      formProps={formProps}
                      labelTextKey={TextKeys.mFRegisterPaymentCustomerIDrLabel}
                    />
                  </FigmaBox>

                  <FigmaBox fullWidth direction="row" align="center" justify="space-between">
                    <FigmaBox fullWidth right spacing={Spacings.large}>
                      <TextContainer textKey={TextKeys.registerPaymentOCR} />
                    </FigmaBox>
                    <MaterialFieldFormik fullWidth name="ocr" formProps={formProps} labelTextKey={TextKeys.mFRegisterPaymentOCRLabel} />
                  </FigmaBox>

                  <FigmaBox fullWidth direction="row" align="center" justify="space-between">
                    <FigmaBox fullWidth right spacing={Spacings.large}>
                      <TextContainer textKey={TextKeys.registerPaymentAmount} text="Currency" />
                    </FigmaBox>
                    <MaterialSelectFieldFormik
                      fullWidth
                      name="currency"
                      options={[
                        { value: Currencies.SEK, label: Currencies.SEK },
                        { value: Currencies.EUR, label: Currencies.EUR },
                        { value: Currencies.DKK, label: Currencies.DKK },
                        { value: Currencies.NOK, label: Currencies.NOK }
                      ]}
                      formProps={formProps}
                    />
                  </FigmaBox>

                  <FigmaBox fullWidth direction="row" align="center" justify="space-between">
                    <FigmaBox fullWidth right spacing={Spacings.large}>
                      <TextContainer textKey={TextKeys.registerPaymentAmount} />
                    </FigmaBox>
                    <MaterialFieldFormik
                      fullWidth
                      name="amount"
                      formProps={formProps}
                      adormentText={formProps.values.currency}
                      adormentTextKey={TextKeys.mFRegisterPaymentAmountLabel}
                    />
                  </FigmaBox>

                  <FigmaBox fullWidth direction="row" align="center" justify="space-between">
                    <FigmaBox fullWidth right spacing={Spacings.large}>
                      <TextContainer textKey={TextKeys.registerPaymentDate} />
                    </FigmaBox>
                    <MaterialDatePickerFormik
                      fullWidth
                      name={'paymentDate'}
                      inputFormat={YYYY_MM_DD}
                      formProps={formProps}
                      labelTextKey={TextKeys.registerPaymentDate}
                      placeHolderTextKey={TextKeys.mFRegisterPaymentDateLabel}
                      value={formProps.values['paymentDate'] as unknown as dayjs.Dayjs}
                      onChange={(value) => formProps.setFieldValue('paymentDate', value)}
                    />
                  </FigmaBox>

                  <FigmaBox fullWidth direction="row" align="center" justify="space-between">
                    <FigmaBox fullWidth right spacing={Spacings.large}>
                      <TextContainer textKey={TextKeys.registerPaymentDecription} />
                    </FigmaBox>
                    <MaterialFieldFormik
                      fullWidth
                      name="description"
                      formProps={formProps}
                      labelTextKey={TextKeys.mFRegisterPaymentDecriptionLabel}
                    />
                  </FigmaBox>
                </FigmaBox>

                <ModalExperimentalFooter>
                  <FigmaBox direction="row" fullWidth align="flex-start" justify="flex-end" gap={Spacings.tiny}>
                    <FigmaButton fullWidth={false} variant="tertiary" textKey={TextKeys.createYearNameButtonCancel} onClick={onClose} />
                    <FigmaButton fullWidth={false} textKey={TextKeys.registerPaymentButton} loading={isLoading} />
                  </FigmaBox>
                </ModalExperimentalFooter>
              </form>
            )}
          </Formik>
        </ModalExperimental>
      )}
    </>
  )
}
