import { Currencies } from './ConfigCurrencies'

enum OnboardingMethods {
  EMAIL = 'EMAIL',
  BANKID = 'BANKID'
}

enum Authentication {
  SWEDISH_BANK_ID = 'SWEDISH_BANK_ID',
  FINNISH_TRUST_NETWORK = 'FINNISH_TRUST_NETWORK'
}

interface Amount {
  value: number
  currency: string
}

// Don't use "?:", instead use " | undefined", as it requires the property to be explicitly added and set to undefined
// This improves readability and makes it easy to reason about the config format quickly
export type ConfigCountry = {
  name: Countries
  textKey: string
  category: {
    accountNumberCharacters: number
    categoryNameCharacters: number
  }
  phone: {
    code: number
    maxLength: number
  }
  currency: Currencies
  numberOfDecimals: number
  separators: {
    decimals: string
    thousands: string
  }
  onboardingMethods: OnboardingMethods[]
  authenticationMethod: Authentication
  formatCurrency: (value: number, currency?: string, style?: 'currency' | 'decimal') => string
  formatAmount: (amountDto: Amount, style?: 'currency' | 'decimal') => string
}

export enum Countries {
  SE = 'SE',
  FI = 'FI',
  DK = 'DK',
  NO = 'NO'
}

export const Locales = {
  [Countries.SE]: 'sv-SE',
  [Countries.FI]: 'fi-FI',
  [Countries.DK]: 'da-DK',
  [Countries.NO]: 'nb-NO'
}

export const ConfigCountries: Record<Countries, ConfigCountry> = {
  [Countries.SE]: {
    name: Countries.SE,
    textKey: 'logInStartSelectCountrySweden',
    category: {
      accountNumberCharacters: 4,
      categoryNameCharacters: 60
    },
    phone: {
      code: 46,
      maxLength: 13
    },
    currency: Currencies.SEK,
    numberOfDecimals: 2,
    separators: {
      decimals: ',',
      thousands: ' '
    },
    onboardingMethods: [OnboardingMethods.BANKID, OnboardingMethods.EMAIL],
    authenticationMethod: Authentication.SWEDISH_BANK_ID,
    formatCurrency: (value, currency = Currencies.SEK, style = 'currency') =>
      Intl.NumberFormat(Locales[Countries.SE], {
        style,
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0
      }).format(value),
    formatAmount: ({ value, currency }, style = 'currency') =>
      Intl.NumberFormat(Locales[Countries.SE], {
        style,
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0
      }).format(value)
  },
  [Countries.FI]: {
    name: Countries.FI,
    textKey: 'logInStartSelectCountrySuomi',
    category: {
      accountNumberCharacters: 4,
      categoryNameCharacters: 60
    },
    phone: {
      code: 358,
      maxLength: 13
    },
    currency: Currencies.EUR,
    numberOfDecimals: 2,
    separators: {
      decimals: ',',
      thousands: ' '
    },
    onboardingMethods: [OnboardingMethods.EMAIL],
    authenticationMethod: Authentication.FINNISH_TRUST_NETWORK,
    formatCurrency: (value, currency = Currencies.EUR, style = 'currency') =>
      Intl.NumberFormat(Locales[Countries.FI], {
        style,
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0
      }).format(value),
    formatAmount: ({ value, currency }, style = 'currency') =>
      Intl.NumberFormat(Locales[Countries.SE], {
        style,
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0
      }).format(value)
  },
  [Countries.DK]: {
    // FIXME denmark
    name: Countries.DK,
    textKey: 'logInStartSelectCountryDK',
    category: {
      accountNumberCharacters: 4,
      categoryNameCharacters: 60
    },
    phone: {
      code: 45,
      maxLength: 13
    },
    currency: Currencies.DKK,
    numberOfDecimals: 2,
    separators: {
      decimals: ',',
      thousands: ' '
    },
    // The customers of Denmark is native, none of these will be used
    onboardingMethods: [OnboardingMethods.EMAIL],
    authenticationMethod: Authentication.SWEDISH_BANK_ID,
    formatCurrency: (value, currency = Currencies.DKK, style = 'currency') =>
      Intl.NumberFormat(Locales[Countries.DK], {
        style,
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0
      }).format(value),
    formatAmount: ({ value, currency }, style = 'currency') =>
      Intl.NumberFormat(Locales[Countries.DK], {
        style,
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0
      }).format(value)
  },
  [Countries.NO]: {
    // FIXME norway
    name: Countries.NO,
    textKey: 'logInStartSelectCountryNO',
    category: {
      accountNumberCharacters: 4,
      categoryNameCharacters: 60
    },
    phone: {
      code: 47,
      maxLength: 13
    },
    currency: Currencies.NOK,
    numberOfDecimals: 2,
    separators: {
      decimals: ',',
      thousands: ' '
    },
    // Norway is without partner, so we need these values
    onboardingMethods: [OnboardingMethods.EMAIL],
    authenticationMethod: Authentication.SWEDISH_BANK_ID,
    formatCurrency: (value, currency = Currencies.NOK, style = 'currency') =>
      Intl.NumberFormat(Locales[Countries.NO], {
        style,
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0
      }).format(value),
    formatAmount: ({ value, currency }, style = 'currency') =>
      Intl.NumberFormat(Locales[Countries.NO], {
        style,
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0
      }).format(value)
  }
}
