import { HeaderMenuLinks } from '../helpers/HeaderMenuContent'
import FigmaBox from '../mynt-components/components/FigmaBox'
import Spacings from '../figma/tokens/Spacings'
import HeaderLogo from './HeaderLogo'
import HeaderLink from './HeaderLink'
import SearchInputComponent from './HeaderSearch'
import HeaderButtonLogout from './HeaderButtonLogout'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'
import { CreateCustomerFormData, CreateCustomerModal } from '../pages/Customers/CreateCustomerModal'
import { useState } from 'react'
import { useCreateCustomer } from '../api/react-query'
import { CUSTOMERS_PATH } from '../routes/constants'
import { useNavigate } from 'react-router'
import { isDev } from 'helpers/common'
import { colors } from 'themes'
import { SessionRole, useSessionRole } from 'contexts/session'
import { Access, hasPermission, useRolePermissionsContext } from 'contexts/permissions'
import { switchToDevAdminRole, switchToDevSalesRole } from 'api'
import { SupportedCountry } from 'api/swagger/definitions/backoffice'

const CountryCodeToLabel = {
  [SupportedCountry.SE]: 'Sweden',
  [SupportedCountry.FI]: 'Finland',
  [SupportedCountry.DK]: 'Denmark',
  [SupportedCountry.NO]: 'Norway'
}

const countryOptions = Object.values(SupportedCountry).map((country) => ({
  value: country,
  label: CountryCodeToLabel[country] || country
}))

interface Props {
  isViewDesktopLarge: boolean
}

const HeaderVariantDesktop: React.FC<Props> = ({ isViewDesktopLarge }) => {
  const permissions = useRolePermissionsContext()
  const { role } = useSessionRole()
  const [showModal, setShowModal] = useState(false)
  const customerMutation = useCreateCustomer()
  const navigate = useNavigate()

  const handleOnCreateCustomer = async (values: CreateCustomerFormData) => {
    const formData = values as CreateCustomerFormData<SupportedCountry>

    return customerMutation.mutateAsync(formData).then((createdCustomer) => {
      setShowModal(false)
      navigate(`${CUSTOMERS_PATH}/${createdCustomer.id}`)
    })
  }

  const handleDevRoleSwitch = async () => {
    if (role === SessionRole.ADMIN) {
      return switchToDevSalesRole()
    }

    return switchToDevAdminRole()
  }

  return (
    <>
      <CreateCustomerModal
        isOpen={showModal}
        onSubmit={handleOnCreateCustomer}
        onClose={() => setShowModal(false)}
        countryOptions={countryOptions}
      />
      <FigmaBox fullWidth direction="row" justify="space-between" align="center">
        <FigmaBox direction="row" justify="flex-start" align="center" gap={isViewDesktopLarge ? Spacings.huge : Spacings.large}>
          <HeaderLogo />
          <FigmaBox direction="row" gap={isViewDesktopLarge ? Spacings.large : Spacings.small}>
            {HeaderMenuLinks.map((menuLink) => {
              if (!hasPermission(permissions, role, menuLink.permissions)) {
                return null
              }

              return <HeaderLink key={menuLink.path} text={menuLink.text} path={menuLink.path} />
            })}
          </FigmaBox>
        </FigmaBox>
        <FigmaBox direction="row" align="center" gap={Spacings.small}>
          {isDev() && (
            <DevButton onClick={handleDevRoleSwitch} variant="secondary">
              Switch to {role === SessionRole.ADMIN ? SessionRole.SALES : SessionRole.ADMIN} role
            </DevButton>
          )}
          <Access permissions="customers.create">
            <StyledButton onClick={() => setShowModal(true)} variant="secondary">
              Create customer
            </StyledButton>
          </Access>
          <SearchInputComponent />
          <HeaderButtonLogout />
        </FigmaBox>
      </FigmaBox>
    </>
  )
}

const StyledButton = styled(Button)`
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 8px;
`

const DevButton = styled(Button)`
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${colors.product};
`

export default HeaderVariantDesktop
