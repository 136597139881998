import config from '../../config'
import EnTexts from '../../figma/langs/en.json'
import FigmaTexts from '../../figma/langs/figma.json'
import SvTexts from '../../figma/langs/sv.json'
import FigmaTextKeys from '../../figma/texts.json'
import { AntiloopTextType, Language } from '../../tiger/interfaces/Antiloop'
import { en, fi, sv } from '../Constants'


type FlatTextRepository = Record<string, string>
type NestedTextRepository = Record<'figma', FlatTextRepository>

const missingTexts = new Set()

const missingTextLogs = (textkey: string) => {
  if (missingTexts.has(textkey)) return

  missingTexts.add(textkey)

  config.enableLogging &&
    config.enableLoggingMissedTexts &&
    console.warn(
      `Unable to find textkey '${textkey}' from lokalise, using fallback instead \n\nThe following textkeys are missing:`,
      Array.from(missingTexts)
    )
}

const getLokaliseTranslations = (textkey: string, language: Language) => {
  const fallbackText = () => {
    if (config.name !== 'production') missingTextLogs(textkey)
    return  FigmaTextKeys[textkey]?.characters || ''
  }

  switch (language) {
    case sv:
      return fallbackText()
    case fi:
      return fallbackText()
    case en:
      return fallbackText()
    default:
      return fallbackText()
  }
}

const getTextFlat = (textKey: AntiloopTextType, language: Language): string => {
  switch (language) {
    case sv:
      return SvTexts[textKey.name] || ''
    case en:
      return EnTexts[textKey.name] || ''
    default:
      return FigmaTexts[textKey.name] || ''
  }
}
/** @deprecated use getText from helpers directory instead (gazelle only) */
export const getText = (textKey: AntiloopTextType, language: Language): string => {
  if (config.enableLokaliseTranslations) {
    return getLokaliseTranslations(textKey.name, language)
  }

  // In Backoffice and Eagle the textkey json files might still be nested, we need to check for that
  const isFlat = [FigmaTexts, SvTexts, EnTexts].every((textRepo) => typeof textRepo?.['figma'] === 'undefined')

  if (isFlat) return getTextFlat(textKey, language)

  // Fix the types, typescript is being really square about the imported json type
  const nestedFigmaTexts = FigmaTexts as unknown as NestedTextRepository
  const nestedSvTexts = SvTexts as unknown as NestedTextRepository
  const nestedEnTexts = EnTexts as unknown as NestedTextRepository

  switch (language) {
    case sv:
      return nestedSvTexts.figma[textKey.name] || ''
    case en:
      return nestedEnTexts.figma[textKey.name] || ''
    default:
      return nestedFigmaTexts.figma[textKey.name] || ''
  }
}


export const TextKeys = FigmaTextKeys
